<template>
    <div>
        <ModalUpload :visible="uploadModalVisible" @close="uploadModalVisible = false" @refresh="getAll" />
        <ModalComments :visible="commentsModalVisible" :source-id="selectedCommentSourceId" @close="commentsModalVisible = false" @refresh="getAll" />
        <ModalRefund :visible="refundModalVisible" :ids="selectedIds" @close="refundModalVisible = false" @refresh="getAll" />

        <div class="card-tail">
            <ClientsQuickSearch @on-selected="onClientChange" :selected="preselectedClient" />
        </div>

        <div class="card-tail card-f-h">
            <div class="flex w-full justify-end" v-if="!isGlobalClientSelected">
                <el-button type="primary" @click="openUploadModal">Ladda upp fil</el-button>
            </div>

            <BankFilesTable :data="itemsData" @openCommentsModal="openCommentsModal" @openRefundModal="openRefundModal" @sortTypeChanged="updateUrlAndGetAll" />

            <Pagination ref="paginationComponent" @change="updateUrlAndGetAll" :total-amount="totalFilesNumber" :page-number-prop="pageNumber" />
        </div>
    </div>
</template>
<script>
import queryString from "query-string";
import Api from "./bank_files.api";

export default {
    data() {
        return {
            itemsData: [],
            totalFilesNumber: 0,
            pageNumber: 1,
            pageSize: 15,
            sortBy: "",
            sortByType: "",
            clientId: queryString.parse(window.location.search).clientId,
            uploadModalVisible: false,
            commentsModalVisible: false,
            refundModalVisible: false,
            selectedCommentSourceId: 0,
            selectedIds: {},
            preselectedClient: {},
        };
    },

    components: {
        ClientsQuickSearch: () => import(/* webpackChunkName: "ClientsQuickSearch" */ "@/components/clientsSearch.vue"),
        Pagination: () => import(/* webpackChunkName: "Pagination" */ "./components/Pagination"),
        BankFilesTable: () => import(/* webpackChunkName: "BankFilesTable" */ "./components/BankFilesTable"),
        ModalUpload: () => import(/* webpackChunkName: "BankFilesUploadModal" */ "./modals/BankFilesUploadModal"),
        ModalComments: () => import(/* webpackChunkName: "BankFilesCommentsModal" */ "./modals/BankFilesCommentsModal.vue"),
        ModalRefund: () => import(/* webpackChunkName: "BankFilesCommentsModal" */ "./modals/BankFilesRefundModal.vue"),
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Bank filer");
        document.title = "Bank filer";
    },

    created() {
        if (!this.isGlobalClientSelected) {
            this.updateUrlAndGetAll();
        } else {
            const { clientId } = queryString.parse(window.location.search);
            this.preselectedClient = {
                value: clientId,
            };
        }
    },

    methods: {
        async onClientChange(client) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.clientId = client.value;
            this.$router.replace({ query }).catch(() => {});
            this.updateUrlAndGetAll({ resetPageNumber: true });
        },

        async getAll() {
            this.$waiting.start("loading");

            const response = await Api.getAll(this.pageNumber, this.pageSize, this.clientId, this.sortBy, this.sortByType);
            this.itemsData = response.data;
            this.totalFilesNumber = parseInt(response.headers["x-pagination-totalcount"]);

            this.$waiting.end("loading");
        },

        updateUrlAndGetAll({ resetPageNumber } = false) {
            setTimeout(() => {
                if (resetPageNumber) {
                    this.$refs.paginationComponent.updateUrl(1);
                }
            }, 100);

            const query = queryString.parse(window.location.search);
            this.pageNumber = resetPageNumber ? 1 : query.pageNumber || this.pageNumber;
            this.pageSize = query.pageSize || this.pageSize;
            this.clientId = query.clientId || "";
            this.sortBy = query.sortBy || "";
            this.sortByType = query.sortByType || "";
            this.getAll();
        },

        openUploadModal() {
            this.uploadModalVisible = true;
        },

        openCommentsModal(sourceId) {
            this.selectedCommentSourceId = sourceId;
            this.commentsModalVisible = true;
        },
        openRefundModal(ids) {
            console.log("🚀 - file: BankFiles.vue:113 - openRefundModal - ids:", ids);
            this.selectedIds = ids;
            this.refundModalVisible = true;
        },
    },

    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
